import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'

const Faq = () => {
  const data = useStaticQuery(graphql`
    query {
      accordionJson {
        title
        items {
          quest
          answer
        }
      }
    }
  `)
  const [show, setShow] = React.useState(false)
  return (
    <Layout
      title="Ответы на частые вопросы"
      description="Ответы на частые вопросы"
      brcr={['Ответы на частые вопросы']}
    >
      <section className="faq_page">
        <Container style={{ position: 'relative' }}>
          <h1>Ответы на частые вопросы</h1>
          <div className={`card`}>
            {data.accordionJson.items
              .map((item, index) => {
                return (
                  <div key={index}>
                    {index === 0 ? (
                      <input
                        type="checkbox"
                        defaultChecked
                        className={`d-none`}
                        id={`item${index}`}
                      />
                    ) : (
                      <input type="checkbox" className={`d-none`} id={`item${index}`} />
                    )}

                    <label className={`quest`} htmlFor={`item${index}`}>
                      {item.quest}
                    </label>
                    <p
                      className={`answer`}
                      dangerouslySetInnerHTML={{ __html: item.answer && item.answer }}
                    />
                  </div>
                )
              })
              .slice(0, show ? data.accordionJson.items.lenght : 3)}
            <hr />

            <button
              className={show ? `${`addInfo`} ${`infoShow`}` : `addInfo`}
              onClick={() => setShow(!show)}
            >
              {show ? 'Скрыть' : 'Показать все'}
            </button>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Faq
